<template>
  <div>
    {{ title }}
    <el-select
      v-model="searchValue"
      :placeholder="placeholder"
      :filterable="filterable"
      :clearable="clearable"
      :disabled="disabled"
      :size="size"
      @clear="onClear"
      @change="onChange"
      :style="{ width: width + 'px' }"
    >
      <el-option v-for="item in searchArr" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: [String, Number],
      default: '70',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    type: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, null],
      default: '',
    },
    introductionType: {
      type: String,
      default: '',
    },
    twoIntroductionType: {
      type: String,
      default: '',
    },
    yearValue: {
      type: [String, null],
      default: '',
    },
    twoValue: {
      type: [String, null],
      default: '',
    },
    threeValue: {
      type: [String, null],
      default: '',
    },
    yearIsShow: {
      type: Boolean,
      default: false,
    },
    threeIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchArr: [],
      searchValue: null,
      dictData: {
        businessLines: [],
        yearList: [],
        projectSource: [],
        businessType: [],
      },
    }
  },
  created() {
    this.getType()
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.searchValue = val
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.searchArr = val.map(v => {
          return { id: v.id, label: v.dictName, value: v.dictVal }
        })
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('input', value)
      if (!this.type) return
      let res = {}
      let arr = []
      let projectPaymentAmountObj = {}
      let queryParams = `date=${this.yearIsShow ? value : this.yearValue}`
      const addParam = (param, value) => {
        if (value) {
          queryParams += `&${param}=${value}`
        }
      }
      // 父项切换
      if (this.yearIsShow) {
        if (this.twoValue) {
          addParam(this.introductionType, this.twoValue)
          if (this.threeValue) {
            addParam(this.twoIntroductionType, this.threeValue)
          }
        } else if (this.threeValue) {
          addParam(this.twoIntroductionType, this.threeValue)
        }
      } else {
        // 子项切换
        // 正常二级关联，没有三级
        if (value && !this.threeIsShow && !this.threeValue) {
          addParam(this.introductionType, value)
        } else {
          // 三级关联
          if (value && !this.twoValue && this.threeIsShow) {
            addParam(this.twoIntroductionType, value)
          }
          if (this.twoValue && this.threeIsShow) {
            addParam(this.introductionType, this.twoValue)
            if (value) {
              addParam(this.twoIntroductionType, value)
            }
          } else if (this.threeValue && !this.threeIsShow) {
            if (value) {
              addParam(this.introductionType, value)
            }
            addParam(this.twoIntroductionType, this.threeValue)
          }
        }
      }

      if (this.type === 'projectQuantit') {
        // 项目数量统计
        res = await this.$api.statistics.queryProjectCountSummary(queryParams)
        const { projectCountStatisticsVo = [] } = res.data
        const keyMappings = {
          notStarted: '未启动',
          inprogress: '正常实施中',
          delayed: '进度滞后',
          maintenance: '运维服务期（质保期）',
          temporarilyPaused: '临时暂停',
          terminatedPendingPayment: '终止（待收款）',
          terminatedNoPayment: '终止（无待收款）',
          completedPaid: '已完结已收款',
          completedPendingPayment: '已完结待收款',
          archived: '归档',
        }
        arr = Object.keys(projectCountStatisticsVo)
          .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: keyMappings[key], // 根据键获取名称
            value: projectCountStatisticsVo[key],
          }))

        this.$emit('change', arr)
      } else if (this.type === 'projectStatistics') {
        // 项目统计表
        res = await this.$api.statistics.queryProjectSummary(queryParams)
        projectPaymentAmountObj = res.data.projectStatisticsView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'Histogram') {
        // 项目年度收入统计
        res = await this.$api.statistics.queryProjectIncomeStatistics(queryParams)
        const { projectIncomeStatisticsVo = [] } = res.data
        projectIncomeStatisticsVo.forEach(item => {
          const businessLinesItem = this.dictData.businessLines.find(
            v => v.dictVal === item.businessCategory
          )
          item.businessCategoryName = businessLinesItem
            ? businessLinesItem.dictName
            : item.businessCategory
        })
        arr = projectIncomeStatisticsVo
        const nameMapping = {
          actualAmount: '项目实际金额',
          contractAmount: '合同金额',
          invoicedAmount: '已开票金额',
          receivedAmount: '已收款金额',
          unreceivedInvoicedAmount: '已开票未收款金额',
          unreceivedUnissuedAmount: '未开票已收款',
          unissuedAmount: '未开票金额',
          totalTax: '总税费',
        }
        const businessLinesInfo = {
          nameArr: [],
          data: [],
        }
        businessLinesInfo.nameArr = projectIncomeStatisticsVo.map(v => v.businessCategoryName)
        // 根据 nameMapping 填充数据
        Object.entries(nameMapping).forEach(([field, fieldName]) => {
          const fieldData = {
            name: fieldName,
            data: [],
          }
          // 对每个业务分类进行数据填充
          businessLinesInfo.nameArr.forEach(category => {
            // 查找该类别对应的项目
            const categoryData = projectIncomeStatisticsVo.find(
              item => item.businessCategoryName === category
            )
            // 根据字段获取对应的值，若不存在则默认为 0
            fieldData.data.push(categoryData ? categoryData[field] : 0)
          })
          // 将字段数据推入 businessLinesInfo.data
          businessLinesInfo.data.push(fieldData)
        })
        this.$emit('change', businessLinesInfo)
        this.$emit('changeData', arr)
      } else if (this.type === 'HistogramMonth') {
        // 项目月度收入统计
        res = await this.$api.statistics.queryMonthlyProjectIncomeStatistics(queryParams)
        const { projectMonthlyIncomeStatisticsVo = {} } = res.data
        const nameMapping = {
          invoicedAmount: '已开票金额',
          proceedsAmount: '已收款金额',
        }
        arr = Object.keys(projectMonthlyIncomeStatisticsVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: nameMapping[key], // 根据键获取名称
            data: projectMonthlyIncomeStatisticsVo[key],
          }))
        const contractPaymentListVoList =
          projectMonthlyIncomeStatisticsVo?.contractPaymentListVoList || []
        this.$emit('changeData', contractPaymentListVoList)
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLines') {
        // 业务种类
        res = await this.$api.statistics.projectBusinessCategorySummary(queryParams)
        arr = res.data?.businessCategoryResponseList.map(item => {
          const lines = this.dictData.businessLines.find(v => v.dictVal === item.categoryName)
          return {
            categoryPaymentAmount: item.categoryPaymentAmount,
            categoryAmount: item.categoryAmount,
            percentage: Number((item.percentage * 100).toFixed(2)),
            paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
            categoryCount: item.categoryCount,
            name: lines ? lines.dictName : item.categoryName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLinesData') {
        // 业务种类统计
        res = await this.$api.statistics.projectQueryBusinessCategorySummaryList(queryParams)
        projectPaymentAmountObj = res.data.businessCategoryResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'BusinessType') {
        // 商务类型
        res = await this.$api.statistics.projecBusinessTypeSummary(queryParams)
        arr = res.data?.businessTypeResponse.map(item => {
          const typeItem = this.dictData.businessType.find(v => v.dictVal === item.businessTypeName)
          return {
            businessTypePaymentAmount: item.businessTypePaymentAmount,
            businessTypeAmount: item.businessTypeAmount,
            businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
            paymentBusinessPercentage: Number((item.paymentBusinessPercentage * 100).toFixed(2)),
            businessTypeCount: item.businessTypeCount,
            name: typeItem ? typeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessTypeData') {
        // 商务类型统计
        res = await this.$api.statistics.projectQueryBusinessTypeSummaryList(queryParams)
        projectPaymentAmountObj = res.data.businessTypeResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'ProjectSource') {
        // 项目来源
        res = await this.$api.statistics.queryProjectSourceSummary(queryParams)
        arr = res.data?.projectSourceResponse.map(item => {
          const typeItem = this.dictData.projectSource.find(
            v => v.dictVal === item.projectSourceName
          )
          return {
            projectSourcePaymentAmount: item.projectSourcePaymentAmount,
            projectSourceAmount: item.projectSourceAmount,
            projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
            paymentProjectPercentage: Number((item.paymentProjectPercentage * 100).toFixed(2)),
            projectSourceCount: item.projectSourceCount,
            name: typeItem ? typeItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'ProjectSourceData') {
        // 项目来源统计
        res = await this.$api.statistics.projectQueryProjectSourceSummaryList(queryParams)
        projectPaymentAmountObj = res.data.projectSourceResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'ProjectHourHistogram') {
        // 项目工时统计
        res = await this.$api.statistics.queryProjectWorkHourSummary(queryParams)
        arr = res.data?.projectWorkHourResponseList || []
        this.$emit('change', arr)
      } else if (this.type === 'PersonageHuorHistogram') {
        // 个人项目工时统计
        res = await this.$api.statistics.queryPersonalProjectWorkHourSummary(queryParams)
        const { personProjectWorkHourList = [] } = res.data?.projectWorkHourResponseVo
        this.$emit('change', personProjectWorkHourList)
      }
    },

    onClear(value) {
      this.$emit('input', value)
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.fr {
  float: right;
  padding: 3px 0;
}
</style>
